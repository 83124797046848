import { template as template_6267a6530db4428b85d7c0cc067ea124 } from "@ember/template-compiler";
const SidebarSectionMessage = template_6267a6530db4428b85d7c0cc067ea124(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
