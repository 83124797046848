import { template as template_e572d092806b4eb48b4e70e51a149edc } from "@ember/template-compiler";
const FKLabel = template_e572d092806b4eb48b4e70e51a149edc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
