import { template as template_6acc33d8c11949b08b8b91015d5c675c } from "@ember/template-compiler";
const FKText = template_6acc33d8c11949b08b8b91015d5c675c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
