import { template as template_651c1f2b77c24da5a29097b5dca2be1e } from "@ember/template-compiler";
import concatClass from "discourse/helpers/concat-class";
const FKSection = template_651c1f2b77c24da5a29097b5dca2be1e(`
  <div class={{concatClass "form-kit__section" @class}} ...attributes>
    {{#if @title}}
      <h2 class="form-kit__section-title">{{@title}}</h2>
    {{/if}}

    {{#if @subtitle}}
      <span class="form-kit__section-subtitle">{{@subtitle}}</span>
    {{/if}}

    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKSection;
